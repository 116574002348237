* {
    margin: 0;
    box-sizing: border-box;
    color: white;
    font-family: "Open Sans", serif;
    font-size: 18px;
}

html, body {
    height: 100%;
}

body {
    background-color: rgb(15, 27, 49);
    background-image: linear-gradient(160deg, #27635d, #164d76);
    background-attachment: fixed;
}

#start > .container {
    height: 100%;
    justify-content: center;
}

h1, h2 {
    font-family: "Roboto Slab", Arial, sans-serif;
    font-size: 32px;
    margin-bottom: 20px;
}
input {
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 24px;
    background-color: rgba(0, 0, 0, .5);
    margin-bottom: 5px;
    text-align: center;
}

.mobile-warn {
    padding: 15px;
    font-size: 20px;
    background: #7c4a06;
}
@media (max-width: 800px) {
    .mobile-warn {
        display: none;
    }
}

.container {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.container > * {
    max-width: 100%;
}

.button {
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 24px;
    background-color: rgba(0, 0, 0, .5);
}

p {
    padding: 10px 0;
}
.truth:not(body) {
    font-size: 16px;
    display: none;
}
body.truth .truth {
    display: block;
    width: 100%;
}

.o2-code, .o3-sentence {
    background: rgba(0, 0, 0, .5);
    padding: 10px 10px;
    width: 100%;
    font-size: 24px;
}
.o2-code.o2-code_correct {
    background: rgba(0, 255, 0, .5);
}
.o2-code.o2-code_incorrect {
    background: rgba(255, 0, 0, .5);
}

.o2-numpad {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto auto;
    padding-top: 20px;
}
.o2-num {
    padding: 5px 5px;
    margin: 2px 2px;
    width: 64px; height: 64px;
}
.o2-num[data-number="0"] {
    grid-column-start: 1;
    grid-column-end: 4;
    width: initial;
}
.o2-done {
    display: none;
}

.o3-sentence {
    margin-bottom: 10px;
}
.o3-word {
    display: inline-block;
    margin: 2px 2px;
}
.o3-done {
    display: none;
}

.o4-maze {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto
        auto auto auto auto auto auto auto auto auto auto auto
        auto auto auto;
}
.maze-i {
    width: 15px;
    height: 15px;
    background: #595959;
}
.maze-i_wall.maze-i_seen {
    background: #212121;
}
.maze-i_empty.maze-i_seen:not(.maze-i_pos) {
    background: white;
}
.maze-i_part.maze-i_seen {
    background: #d9dd3c;
}
.maze-i_part.maze-i_seen.maze-i_part_collected {
    background: #d4d782;
}
.maze-i_pos {
    background: #008000;
}

.o4-controls {
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 10px 0;
}
.o4-controls > * {
    margin: 2px;
}
.o4-up {
    grid-column-start: 2;
}
.o4-left {
    grid-column-start: 1;
    grid-row-start: 2;
}
.o4-down {
    grid-column-start: 2;
    grid-row-start: 2;
}
.o4-right {
    grid-column-start: 3;
    grid-row-start: 2;
}

.o4-next {
    display: none;
    grid-column: 1 / 4;
    grid-row-start: 3;
}

#p5 {
    overflow-x: hidden;
}
.o5-bar {
    position: relative;
    background: rgba(255, 255, 255, .5);
    width: 100%;
    height: 10px;
    margin-bottom: 100px;
}
.o5-pointer {
    position: absolute;
    background: url("./assets/exhaust-pipe.svg");
    background-size: 100px 100px;
    transform: rotate(90deg);
    width: 100px; height: 100px;
    top: 0;
    left: -60px;
    animation: 2s ease-in-out 0s infinite alternate o5pointer;
}
@keyframes o5pointer {
    from { left: -60px; }
    to { left: calc(100% - 60px); }
}
.o5-target {
    position: absolute;
    background: #5ffb5f;
    width: 20px;
    height: 10px;
    top: 0;
    left: calc(50% - 10px);
}

.o6-answer {
    margin-bottom: 5px;
    width: 100%;
}
.o6-correct.o6-shown {
    background: rgba(0, 128, 0, .5);
}
.o6-shown:not(.o6-correct) {
    background: rgba(200, 0, 0, .5);
}
.o6-done {
    display: none;
}
